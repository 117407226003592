/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
html,
body {
	height: 100%;
}
#root {
  overflow: auto;
}
.section {
  max-width: 600px;
}
.App {
  background-color: #bcb971;
  display: block;
  text-align: center;
}
.App-header,
.App-main {
  display: block;
  position: relative;
  z-index: 1;
  padding: 10px;
}
.Background-1 {
  image-rendering: pixelated;
  background-image: url('./assets/images/foreground.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
}
.Background-2 {
  image-rendering: pixelated;
  background-image: url('./assets/images/midtrees.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
}
.Background-3 {
  image-rendering: pixelated;
  background-image: url('./assets/images/midrobot.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
}
.Background-4 {
  image-rendering: pixelated;
  background-image: url('./assets/images//cityscape.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
}
@media (min-width: 860px) { 
  .App {
    display: flex;
    gap: 1rem;
  }
  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    gap: 1rem;
    margin-left: 1rem;
    z-index: 1;
  }
  .App-main {
    margin-top: 112px;
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    height: calc(100vh - 132px);
    gap: 1rem;
    z-index: 1;
  }
}
.App-tool {
  width: 100%;

}
.App-price-info {
 width: 100%;
}
.App-logo-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 10;
}
.App-logo {
  pointer-events: none;
}
.App-welcome {
  margin-top: -65px;
  width: 100%;
}
.Box {
  margin-top: 19px;
}
.Box-header {
  display: flex;
  height: 45px;
}
.Box-bottom {
  display: flex;
  height: 45px;
}
.Box-content {
  display: flex;
  height: 100%;
}
.Box-header-left {
  background-image: url('./assets/images/box-corner.png');
  width: 45px;
}
.Box-header-center {
  background: url('./assets/images/box-border-top.png') repeat-x 0px 4px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.Box-header-right {
  background: url('./assets/images/box-corner-t-r.png') right;
  width: 45px;
}
.Box-content-left {
  background: url('./assets/images/box-border-side.png') repeat-y 6px 0px;
  width: 45px;
}
.Box-content-center {
  background-color: #0c0c1c;
  width: 100%;
  color: #1c8d29;
  font-weight: 600;
  padding-top: 12px;
  font-size: 14px;
}
.Box-content-right {
  background: url('./assets/images/box-border-side-r.png') repeat-y right;
  width: 45px;
}
.Box-bottom-left {
  background-image: url('./assets/images/box-corner.png');
  width: 45px;
  transform: scale(1, -1);
}
.Box-bottom-center {
  background: url('./assets/images/box-border-bottom.png') repeat-x;
  width: 100%;
}
.Box-bottom-right {
  background: url('./assets/images/box-corner-b-r.png') right;
  width: 45px;
}
.Box-header-label {
  display: flex;
  height: 65px;
  margin-top: -20px;
}
.Box-header-label-left {
  background: url('./assets/images/box-label-side.png') no-repeat;
  height: 100%;
  width: 41px;
}
.Box-header-label-content {
  background: url('./assets/images/box-label.png') repeat-x;
  font-size: 18px;
  line-height: 65px;
  color: #ccff99;
  font-weight: 600;
}
.Box-header-label-right {
  background: url('./assets/images/box-label-side.png') no-repeat;
  width: 41px;
  transform: scaleX(-1);
}
.App-collection-field {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('./assets/images/label.png') no-repeat center center;
  height: 56px;
  line-height: 56px;
}

.App-collection-field label {
  display: inline-block;
  width: 180px;
  margin-right: 30px;
  height: 56px;
  line-height: 56px;
  color: #8c9fb4;
}
.App-collection-field input {
  color: #ccff99;
  font-size: larger;
  width: 48px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background-color: #1c8d29;
  border: none;
}


.App-link {
  color: #61dafb;
}


/* 
Generic Styling, for Desktops/Laptops 
*/
table { 
  width: 100%;
}
thead td {
  border-bottom: 1px solid #1c8d29;
}
th {   
  font-weight: bold; 
}
td, th { 
  padding: 6px; 
  text-align: left; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Loading:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}}

